import React from "react";
import * as styles from "./imageGrid.module.css";

const ImageGrid = ({ images, alt }) => {
  return (
    <div className={styles.imageGrid}>
      {images.map((image, index) => (
        <img
          src={image}
          className={styles.imageGridItem}
          key={index}
          alt={alt}
        />
      ))}
    </div>
  );
};

export default ImageGrid;
