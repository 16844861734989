import React, { useEffect } from "react";
import * as styles from "./header.module.css";
import { Link } from "gatsby";

const Header = () => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h3>Dream Big. Take Massive Actions.</h3>
          <h1>
            Side Hustles & Passive Income Ideas <span>That Work</span>
          </h1>
          <p>
            <span>Sleepless Yogi</span> is the <span> #1 community</span> for
            learning about low effort side hustles and passive income ideas.
            {/* Helped{" "}
            <span>3,500+</span> students learn Web Development. And{" "}
            <span>300+</span> start their online business. */}
          </p>

          <div>
            <svg width="0" height="0">
              <filter id="filter">
                <feTurbulence
                  type="fractalNoise"
                  baseFrequency=".01"
                  numOctaves="6"
                />
                <feDisplacementMap in="SourceGraphic" scale="100" />
              </filter>
            </svg>
            <div className={styles.slider_belowbtn1}>
              <Link
                to="https://sleeplessyogi.substack.com/archive"
                title="Newsletter"
                target="_blank"
                class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
              >
                <div className={`px-6 py-1`}>
                  <span>Check Out My Newsletter</span>
                  <div className={styles.back}></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
