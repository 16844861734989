import React from "react";
import ImageGrid from "./ImageGrid";
import * as styles from "./testimonials.module.css";

const Testimonials = () => {
  const images = [
    "https://i.imgur.com/dj8qAM2.webp",
    "https://i.imgur.com/whLmoxS.webp",
    "https://i.imgur.com/lotZ5k0.webp",
    "https://i.imgur.com/i2AVZqh.webp",
    "https://i.imgur.com/7TgpgCD.webp",
    "https://i.imgur.com/N8QuVqu.webp",
    "https://i.imgur.com/wtl4TyE.webp",
    "https://i.imgur.com/AUA1PJm.webp",
  ];

  // const images = [
  //   ["https://i.imgur.com/dj8qAM2.webp", "https://i.imgur.com/whLmoxS.webp"],
  //   ["https://i.imgur.com/lotZ5k0.webp", "https://i.imgur.com/i2AVZqh.webp"],
  //   ["https://i.imgur.com/N8QuVqu.webp", "https://i.imgur.com/wtl4TyE.webp"],
  //   ["https://i.imgur.com/AUA1PJm.webp", "https://i.imgur.com/7TgpgCD.webp"],
  // ];

  return (
    <div className={`${styles.gridCenter} mt-12`}>
      <h2
        className={`flex items-center justify-left text-purple-800 ${styles.heading}`}
      >
        Testimonials
      </h2>
      <ImageGrid images={images} alt={"testimonials"} />
    </div>
  );
};

export default Testimonials;
