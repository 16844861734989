import React from "react";
import * as styles from "./faq.module.css";
import { Link } from "gatsby";

const Faq = () => {
  return (
    <section className={styles.section_wrapper}>
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <h2 class="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-black mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          Questions you might be asking...
        </h2>
        <div class="mx-auto max-w-screen-md mb-8 lg:mb-12">
          <div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-black">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5 text-gray-900 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                What Is Print On Demand?
              </h3>
              <p class="text-gray-900 dark:text-gray-900">
                Print on Demand is a business model that allows entrepreneurs to
                sell apparel and custom-printed items without the need for
                upfront inventory, printing equipment, or shipping logistics.
                This reduces printing, warehousing, and shipping costs while
                eliminating risk from unsold inventory. With print on demand,
                entrepreneurs can focus on creating and marketing their designs,
                while a POD company like{" "}
                <a
                  href="https://www.printful.com/give-5-get-5/KTE16E"
                  target="_blank"
                >
                  Printful
                </a>{" "}
                and{" "}
                <a href="https://try.printify.com/yogi" target="_blank">
                  Printify
                </a>{" "}
                handles the printing, packaging, and shipping of the orders.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-black">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5 text-gray-900 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Can I Start a Print on Demand Business with No Money?
              </h3>
              <p class="text-gray-900 dark:text-gray-900">
                Starting a print on demand business without any money may be
                challenging, but it's not impossible. But, keep in mind, that
                once you receive customer orders - you'll need some capital to
                fulfill them. And, while there are costs involved, such as
                design tools and research tools, there are also free
                alternatives available.
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-black">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5 text-gray-900 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                How Much Can You Earn From Print On Demand?
              </h3>
              <p class="text-gray-900 dark:text-gray-900">
                The statistics reveal that successful PoD businesses maintain
                profit margins ranging from 30% to 50%. For instance, if you
                sell a product for $25 and it costs $10 to produce, you're
                looking at a profit margin of $15. I would encourage you to play
                around with the pricing and discounts - to find a balance
                between your costs and profit. And ensure you make enough to
                keep your business alive for the long term. To ensure you are
                running a profitable e-commerce business, you can use{" "}
                <a
                  href="https://try.printify.com/etsy-calculator"
                  target="_blank"
                >
                  Printify's Profit Calculator
                </a>
                .
              </p>
            </div>
            <div class="mb-10">
              <h3 class="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-black">
                <svg
                  class="flex-shrink-0 mr-2 w-5 h-5 text-gray-900 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                Is Print On Demand Still Profitable [In 2024]
              </h3>
              <p class="text-gray-900 dark:text-gray-900">
                Yes, Print on demand is still profitabl in 2024 - given, that
                you follow the strategies mentioned{" "}
                <Link
                  to={"/posts/is-print-on-demand-still-profitable/"}
                  rel="is-print-on-demand-still-profitable"
                >
                  in this article about POD strategies
                </Link>
                . If you do the right product research and design products that
                resonates with your audience, you can start earning passive
                income in minimum time required.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.slider_belowbtn1}>
        <Link
          to="/posts/"
          title="Blog"
          class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
        >
          <div className={`px-6 py-1`}>
            <span>Read Latest Blog Posts</span>
            <div className={styles.back}></div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Faq;
