import React from "react";
import Header from "../home/header/Header";
import Courses from "../home/courses/Courses";
import Footer from "./footer/Footer";
import Articles from "./articles/Articles";
import ArticleList from "./../../components/articleList/ArticleList";
import Faq from "./../../components/faq/faq";
import Navbar from "./navbar/Navbar";
import BlogTagCloud from "../blogs/blogTagCloud/BlogTagCloud";
import SEO from "../Seo";
import Testimonials from "./../newsletter/Testimonials";

const podArticles = [
  {
    title: "What Is Print On Demand - A Step By Step Guide",
    url: "/posts/what-is-print-on-demand-a-step-by-step-guide/",
  },
  {
    title: "How Much Can You Earn From Print On Demand?",
    url: "/posts/how-much-can-you-earn-from-print-on-demand/",
  },
  {
    title: "How To Start Print On Demand Business?",
    url: "/posts/how-to-start-print-on-demand-business/",
  },
  {
    title: "How to Choose a Print on Demand Niche?",
    url: "/posts/how-to-choose-a-print-on-demand-niche/",
  },
  {
    title: "Best Tools For Print On Demand Business",
    url: "/posts/best-tools-for-print-on-demand-business/",
  },
  {
    title: "How Do You Increase Sales On Print On Demand",
    url: "/posts/how-do-you-increase-sales-on-print-on-demand/",
  },
  {
    title: "How to Use Printful with Etsy: Simple Guide",
    url: "/posts/how-to-use-printful-with-etsy-simple-guide/",
  },
];

const etsyArticles = [
  {
    title: "How To Make Money On Etsy In 2024 - Complete Guide",
    url: "/posts/how-to-make-money-on-etsy-complete-guide/",
  },
  {
    title: "How to Make $100/day On Etsy?",
    url: "/posts/how-to-make-profit-etsy-ecom-business/",
  },
  {
    title: "How Much Does It Cost to Start and Sell on Etsy?",
    url: "/posts/how-much-does-it-cost-to-start-and-sell-on-etsy/",
  },
  {
    title: "Can You Really Earn Passive Income On Etsy?",
    url: "/posts/can-you-really-earn-passive-income-on-etsy/",
  },
  {
    title: "How To Be Successful On Etsy? Your Complete 15-Step Checklist",
    url: "/posts/how-to-be-successful-on-etsy-your-complete-checklist/",
  },
];

const advancedArticles = [
  {
    title: "How to Sell Digital Downloads on Etsy: Complete Guide",
    url: "/posts/how-to-sell-digital-downloads-on-etsy-complete-guide/",
  },
  {
    title: "How to Make Money Using Canva and Sell on Etsy",
    url: "/posts/how-to-make-money-using-canva-sell-on-etsy/",
  },
  {
    title: "Best Etsy SEO Tools to Boost Your Shop",
    url: "/posts/best-etsy-seo-tools-to-boost-your-shop/",
  },
  {
    title: "Best Things to Sell on Etsy for Success",
    url: "/posts/best-things-to-sell-on-etsy-for-success/",
  },
  {
    title: "Can You Have Multiple Etsy Shops",
    url: "/posts/can-you-have-multiple-etsy-shops/",
  },
  {
    title: "How Etsy Ads Work",
    url: "/posts/how-etsy-ads-work/",
  },
];

const Home = () => {
  return (
    <>
      <SEO />
      <Navbar />
      <Header />
      <ArticleList
        articles={etsyArticles}
        title={
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
            How Do I <span>Sell On Etsy</span>
          </h2>
        }
        subTitle="Learn to make $100/month on Etsy, then scale it to $100/day and beyond - even as a beginner."
      />
      <ArticleList
        articles={podArticles}
        title={
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
            Start <span>Print On Demand Business</span> Today
          </h2>
        }
        subTitle="Learn to start your e-commerce print on demand business with these
        step by step guides."
        themeClass={"ternary"}
      />
      <ArticleList
        articles={advancedArticles}
        title={
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
            Etsy <span>Advanced Strategies</span>
          </h2>
        }
        subTitle="Advanced techniques to dominate the Etsy, Digital products, and POD markets."
        themeClass={"ternary"}
      />
      <Articles />
      <Courses />
      <BlogTagCloud />
      <Testimonials />
      <Faq />
      {/* <Testimonial /> */}
      <Footer />
    </>
  );
};

export default Home;
