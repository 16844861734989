// extracted by mini-css-extract-plugin
export const mainSection = "course-module--mainSection--3VVLL";
export const contentWrap = "course-module--contentWrap--1Wcoi";
export const content = "course-module--content--245aW";
export const boxWrapper = "course-module--boxWrapper--19O8K";
export const allCourses = "course-module--allCourses--2J-RB";
export const iconBox = "course-module--iconBox--3Utf9";
export const btnWrap = "course-module--btnWrap--3FJvX";
export const markerImgWrapper = "course-module--markerImgWrapper--2JPQk";
export const markerImg = "course-module--markerImg--3t5Mm";
export const iconText = "course-module--iconText--1Bn8B";
export const btn4 = "course-module--btn4--2BxIm";