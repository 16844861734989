import React from "react";
import Home from "../components/home";

const IndexPage = () => {
  return (
    <>
      <Home />
    </>
  );
};

export default IndexPage;
