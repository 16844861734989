import React from "react";
import * as styles from "./course.module.css";
import { Link } from "gatsby";
import markerImage from "../../../images/Marker1.png";
import { SiJavascript } from "@react-icons/all-files/si/SiJavascript";
import { SiReact } from "@react-icons/all-files/si/SiReact";
import { SiInstagram } from "@react-icons/all-files/si/SiInstagram";
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight";

const courses = [
  {
    url: "https://ngninja.gumroad.com/l/html2react/blog",
    title: "HTML To React",
    description: "The Ultimate Web Development Guide",
    iconComponent: <SiReact />,
  },
  {
    url: "https://ngninja.gumroad.com/l/bizBook/blog",
    title: "Make Money on Instagram",
    description: "Step by Step Guide to Make Money on Instagram",
    iconComponent: <SiInstagram />,
  },
  {
    url: "https://ngninja.gumroad.com/l/masterJS/blog",
    title: "Master JavaScript - Bootcamp",
    description: "Learn through examples. (FREE trial! Cancel anytime)",
    iconComponent: <SiJavascript />,
  },
];

const Courses = () => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h2>Specialized Training Courses</h2>
          <div className="grid justify-items-center mt-8">
            <a
              href="https://www.sleeplessyogi.com/EtsyConsultation"
              target="_blank"
              className="max-w-[600px]"
            >
              <img
                className="rounded"
                src={"https://i.imgur.com/cdzACYX.webp"}
                alt="Etsy Consultation"
              />
            </a>
          </div>
          <div className={styles.boxWrapper} id="trigger-container">
            {courses.map((course) => (
              <div className={styles.iconBox} id="headline">
                <Link to={course.url} target="_blank" title={course.title}>
                  <div className={styles.markerImgWrapper}>
                    <span className={styles.markerImg}>
                      <img src={markerImage} alt="mark" />
                      <span className={styles.iconText}>
                        {course.iconComponent}
                      </span>
                    </span>
                  </div>
                  <h2>{course.title}</h2>
                  <p>{course.description}</p>
                  <div className={styles.btnWrap}>
                    <a>
                      <span>
                        <FiArrowRight />
                      </span>
                    </a>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className={styles.allCourses}>
            <Link
              to="https://ngninja.gumroad.com/"
              target="_blank"
              title="All courses"
              class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
            >
              <div className={`px-6 py-1`}>
                <span>View All Courses</span>
                <div className={styles.back}></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
